import { processDiff } from "./ApiDSLReplayUtils";
import BaseDSLReplayManager, {
  ProcessDiffFuncType,
  ReplayManagerDSLType,
} from "./BaseDSLReplayManager";

const processApiDiff = processDiff as ProcessDiffFuncType;

class SingleApiDSLReplayManager extends BaseDSLReplayManager {
  constructor(initialDsl: ReplayManagerDSLType) {
    super(initialDsl, processApiDiff);
  }
}

// manages mutiple replay stacks, one per API
class ApiDSLReplayManager {
  replayManagersByApiId = new Map<string, SingleApiDSLReplayManager>();
  initializeManager(apiId: string, dsl: ReplayManagerDSLType) {
    if (!this.replayManagersByApiId.has(apiId)) {
      const newReplayManager = new SingleApiDSLReplayManager(dsl);
      this.replayManagersByApiId.set(apiId, newReplayManager);
    }
  }

  clear(apiId: string, dsl: ReplayManagerDSLType) {
    const replayManager = this.replayManagersByApiId.get(apiId);
    if (replayManager) {
      replayManager.clear(dsl);
    } else {
      console.error("No replay manager found for apiId", apiId);
    }
  }

  replay(apiId: string, replayType: "UNDO" | "REDO", times?: number) {
    const replayManager = this.replayManagersByApiId.get(apiId);
    if (replayManager) {
      return replayManager.replay(replayType, times);
    } else {
      console.log("No replay manager found for apiId", apiId);
    }
  }

  update(apiId: string, dsl: ReplayManagerDSLType) {
    const replayManager = this.replayManagersByApiId.get(apiId);
    if (replayManager) {
      replayManager.update(dsl);
    } else {
      console.log("No replay manager found for apiId", apiId);
    }
  }

  clearLogs(apiId: string) {
    const replayManager = this.replayManagersByApiId.get(apiId);
    if (replayManager) {
      replayManager.clearLogs();
    } else {
      console.log("No replay manager found for apiId", apiId);
    }
  }
}

export default new ApiDSLReplayManager();
